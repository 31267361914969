
.row-content .heading-block {
    margin-bottom: 20px;
}
.row-content .heading-block span {
    margin-bottom: 20px;
}
.row-content .heading-block:after {
    margin-top: 10px;
}
.row-content .heading {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.row-content .picture {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.row-content img {
    max-height: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.row-content .heading .heading-title {
    font-size:60px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 46px;
    color: var(--secondary-color);
}
.row-content .heading h1 {
    font-size:39px;
    margin-top:0px;
    margin-bottom: 10px;
    line-height: 46px;
    color: #333;
}
.row-content .heading h2 {
    font-weight: 300;
    font-size: 1.25rem;
}
.row-content div.text {
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 30px;
}
.row-content div.text p {
    margin-bottom: 10px;
}
.row-content div.text ul {
    list-style-type: none;
    margin-left:10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.row-content div.text ul ul {
    margin-left:20px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.row-content div.text ul li:not(:first-child) {
    margin-top: 5px;
}
.row-content div.text ul li:not(:last-child) {
    padding-bottom: 5px;
}
.row-content div.text ul li:before {
    font-family: "font-icons";
    content: "\e77d"; /* "\e6c8"; "\e71e" */
    color: var(--secondary-color);
    margin-right: 10px;
}


.row.inline {
    justify-content: center;
}
@media (max-width: 575.98px) {
.contact-buttons a.button {
        display: block;
        padding:9px 16px;
}
}
@media (max-width: 399.98px) {
.contact-buttons a.button {
        font-size: 14px;
}
}


.services .card {
    transition: all .25s;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
    cursor: pointer;
    height:100%;
}
.services .card:hover {
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
}
.services .card:hover a.button {
    background-color: #444;
    color: #FFF;
    border-color: transparent !important;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
}
.services .card .card-body {
    display:flex;
    flex-direction: column;
}
.services .card .card-body p {
    flex-grow: 1;
}
.services .card a.button {
    margin:20px 0px 0px 0px;
    display: block;
    text-align: center;
}



.teaser .col-md-7 {
    text-align: right;
}


.video .iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 57%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.video .responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* device-lg */
@media (min-width: 1200px) {
.video .iframe-container {
    margin-top:0px;
}
}

/* device-md */
@media (min-width: 992px) and (max-width: 1199px) {
.video .iframe-container {
    margin-top:40px;
}
}

/* device-sm */
@media (min-width: 768px) and (max-width: 991px) {
.video .iframe-container {
    margin-top:80px;
}
}



/* device-lg */
@media (min-width: 1200px) {
.demo-shop img {
    margin-top:10px;
}
}

/* device-md */
@media (min-width: 992px) and (max-width: 1199px) {
.demo-shop img {
    margin-top:35px;
}
}

/* device-sm */
@media (min-width: 768px) and (max-width: 991px) {
.demo-shop img {
    margin-top:60px;
}
}


.latest-news .entry-image img {
  max-height: 200px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}



.home .back-to-shop {
	margin:0px;
	padding:30px;
}
@media (max-width: 575.98px) {
.home .back-to-shop {
		margin:0px;
		padding:30px 0px;
}
}
.home .back-to-shop .logo {
    height: 100px;
    width: 100px;
    padding: 5px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.075);
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
	margin-left:auto;
}
.home .back-to-shop h3 {
	margin-bottom:8px;
}
.latest-news-home {
	padding-top:30px;
	margin-bottom:30px;
}
.latest-news-home .entry-content p {
	font-weight:lighter;
}
.latest-news-home .entry {
	box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
	padding-bottom: 10px;
	height:100%;
}
.latest-news-home .entry > div {
	margin-left:10px;
	margin-right:10px;
}
.latest-news-home .entry > div.entry-image {
	margin:0px;
}
.video {
	background:#ffffff;
	padding-top:30px;
}
.video .line {
	margin:0px;
	margin-bottom:30px;
}
.teaser {
	background:#f9f9f9;
	padding-top:30px;
}
.teaser .line {
	display:none;
}
.demo-shop {
	background:#ffffff;
	padding-top:30px;
}
.demo-shop .line {
	display:none;
}


